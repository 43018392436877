import { GeoJSON, Point } from 'geojson';
import SuperCluster from 'supercluster';

import { MapCoordinatesType } from '@portals/types';

import {
  GoogleMapsType,
  MapFeature,
  SuperClusterPoint,
  SuperClusterType,
} from './device-location-widget.types';

export function createClusterMap(
  bounds: [number, number, number, number],
  points: Array<SuperClusterPoint>,
  zoom: number
): [[], undefined] | [Array<MapFeature>, SuperClusterType] {
  if (!bounds) {
    return [[], undefined];
  }

  const index = new SuperCluster({});

  index.load(points);

  return [index.getClusters(bounds, zoom), index];
}

export function getMapBounds(
  maps: GoogleMapsType,
  points: Array<GeoJSON.Feature<Point>>,
  base: MapCoordinatesType
) {
  const bounds = new maps.LatLngBounds();

  if (points.length === 0) {
    bounds.extend(new maps.LatLng(base.lat, base.lng));
  } else {
    points.forEach((point) => {
      bounds.extend(
        new maps.LatLng(
          point.geometry.coordinates[1],
          point.geometry.coordinates[0]
        )
      );
    });
  }

  return bounds;
}
