import { noop } from 'lodash/fp';
import { useState } from 'react';

import { FileState } from './types';

interface UseFileUploaderDropzoneParams {
  initialFileUrls?: string[];
  onDeleteUploadedFile?: (fileUrl: string) => void;
}

export function useFileUploaderDropzone(
  { initialFileUrls, onDeleteUploadedFile }: UseFileUploaderDropzoneParams = {
    initialFileUrls: undefined,
    onDeleteUploadedFile: noop,
  }
) {
  const [files, setFiles] = useState<Array<FileState>>([]);
  const [uploadedFileUrls, setUploadedFileUrls] = useState(initialFileUrls);

  const isLoading = files.some(({ state }) => state !== 'finished');

  const isFilesEmpty = files.length === 0 && uploadedFileUrls?.length === 0;

  return {
    isLoading,
    isFilesEmpty,
    files,
    setFiles,
    uploadedFileUrls,
    setUploadedFileUrls,
    onDeleteUploadedFile,
  };
}
