import {
  Button,
  createStyles,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Space,
  Stack,
  Switch,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import {
  CreateDeviceModelFileParams,
  useCreateDeviceModelFile,
  useFileInfoById,
  useUpdateDeviceModelFile,
} from '@portals/api/partners';
import { FileUploaderDropzone, useFileUploaderDropzone } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { PartnerFileResponseType } from '@portals/types';
import { ChecksumAdornment, SignatureAdornment } from '@portals/ui';

export interface FileUploaderProps
  extends ModalProps<{
    modelId: string;
    fileId?: string;
    initialValues?: PartnerFileResponseType | undefined;
  }> {
  isLoading?: boolean;
}

const FileUploader = ({ data, closeMe, isLoading }: FileUploaderProps) => {
  const { classes, theme, cx } = useStyles();

  const { modelId, fileId, initialValues } = data;

  const createDeviceModelFile = useCreateDeviceModelFile(modelId);
  const updateDeviceModelFile = useUpdateDeviceModelFile(modelId);

  const error =
    createDeviceModelFile.error?.error || updateDeviceModelFile.error?.error;

  const inProgress =
    createDeviceModelFile.isLoading || updateDeviceModelFile.isLoading;

  const [isSubmitted, setIsSubmitted] = useState(!!initialValues);

  const onDeleteUploadedFile = (fileToDeleteUrl: string) => {
    const withoutFileToDelete = uploadedFileUrls?.filter(
      (fileUrl) => fileUrl !== fileToDeleteUrl
    );

    setUploadedFileUrls(withoutFileToDelete);
  };

  const {
    files,
    setFiles,
    isFilesEmpty,
    isLoading: uploaderIsLoading,
    uploadedFileUrls,
    setUploadedFileUrls,
  } = useFileUploaderDropzone({
    initialFileUrls: initialValues?.url ? [initialValues.url] : [],
    onDeleteUploadedFile,
  });

  const form = useForm<Partial<PartnerFileResponseType>>({
    initialValues: initialValues || {
      name: '',
      desc: '',
      file_type: 'firmware',
      version: '1.0.0',
      checksum: '',
      signature: '',
      notes: '',
      public_notes: '',
      published: true,
    },
  });

  function handleAdd(formData: CreateDeviceModelFileParams) {
    createDeviceModelFile.mutate(formData, {
      onSuccess: () => closeMe(),
    });
  }

  const handleEdit = (updatedFile: PartnerFileResponseType) => {
    updateDeviceModelFile.mutate(updatedFile, {
      onSuccess: () => closeMe(),
    });
  };

  const handleSubmit = (values: CreateDeviceModelFileParams) => {
    if (isFilesEmpty) {
      setIsSubmitted(true);

      return;
    }

    const updatedFile = {
      ...values,
      url: files?.[0]?.fileUrl,
    };

    if (fileId) {
      handleEdit(updatedFile as PartnerFileResponseType);
    } else {
      handleAdd(updatedFile);
    }
  };

  useUpdateEffect(
    function updateFileNameOnFileUpload() {
      if (files?.[0]?.fileUrl) {
        form.setFieldValue('name', files[0].file.name);
      }
    },
    [files]
  );

  return (
    <Modal
      opened
      onClose={closeMe}
      title={fileId ? 'Edit file' : 'Upload new file'}
      padding="xl"
    >
      <LoadingOverlay visible={isLoading} />

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing={0}>
          <ScrollArea.Autosize mah="60vh" offsetScrollbars>
            <Stack>
              {!initialValues || (initialValues && !initialValues.published) ? (
                <>
                  <Group position="apart" align="center" noWrap>
                    <Stack spacing={4}>
                      <Text size="md" color="gray.9">
                        Available for lab accounts only
                      </Text>

                      <Text size="xs" color="gray.6">
                        Enable this option to make this file available on lab
                        accounts only. This is useful if you want to test a new
                        firmware version on lab devices before publishing to
                        your customers.
                      </Text>
                    </Stack>

                    <Switch
                      checked={!form.values.published}
                      onChange={(event) =>
                        form.setFieldValue(
                          'published',
                          !event.currentTarget.checked
                        )
                      }
                    />
                  </Group>

                  <Divider w="100%" color="gray.3" />
                </>
              ) : null}

              <Stack spacing="xs">
                <FileUploaderDropzone
                  files={files}
                  setFiles={setFiles}
                  uploadedFileUrls={uploadedFileUrls}
                  onDeleteUploadedFile={onDeleteUploadedFile}
                  dropzoneProps={{
                    className: cx(classes.dropzone, {
                      [classes.dropzoneError]: isSubmitted && isFilesEmpty,
                    }),
                  }}
                />

                {isSubmitted && isFilesEmpty && (
                  <Text color="red" size="sm">
                    File is required
                  </Text>
                )}
              </Stack>

              <TextInput
                label="File name"
                required
                disabled={uploaderIsLoading}
                icon={uploaderIsLoading ? <Loader size="xs" /> : null}
                {...form.getInputProps('name')}
                data-testid="file-name-input"
              />

              <TextInput
                label="Description"
                {...form.getInputProps('desc')}
                data-testid="file-description-input"
              />

              <TextInput
                label="File Type"
                {...form.getInputProps('file_type')}
                data-testid="file-type-input"
              />

              <TextInput
                label="Version"
                required
                {...form.getInputProps('version')}
                data-testid="file-version-input"
              />

              <TextInput
                label="Checksum"
                {...form.getInputProps('checksum')}
                rightSection={
                  <ChecksumAdornment docsUrl="https://dev.xyte.io/docs/files" />
                }
                data-testid="file-checksum-input"
              />

              <Textarea
                label="Signature"
                {...form.getInputProps('signature')}
                rightSection={
                  <SignatureAdornment docsUrl="https://dev.xyte.io/docs/files" />
                }
                data-testid="file-signature-input"
              />
              <TextInput
                label="Notes"
                {...form.getInputProps('notes')}
                data-testid="file-notes-input"
              />

              <Textarea
                label="Public Notes"
                {...form.getInputProps('public_notes')}
                data-testid="file-public-notes-input"
              />

              <Space h="sm" />
            </Stack>
          </ScrollArea.Autosize>

          <Stack
            sx={{ borderTop: `1px solid ${theme.colors.gray[3]}` }}
            pt="md"
          >
            <Group position="right">
              {error && <Text color="red">{error}</Text>}
            </Group>

            <Group position="right">
              <Button variant="default" onClick={closeMe}>
                Cancel
              </Button>

              <Button
                type="submit"
                loading={inProgress || uploaderIsLoading}
                data-testid="add-file-button"
              >
                {fileId ? 'Save File' : 'Add File'}
              </Button>
            </Group>
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
};

const useStyles = createStyles((theme) => ({
  dropzone: {
    height: 150,
  },
  dropzoneError: {
    borderColor: theme.colors.red[7],
  },
  arrowContainer: {
    marginTop: '6.2%', // using percentage in order handle errors space
  },
}));

type FileUploaderModalWrapperProps = React.ComponentProps<typeof FileUploader>;

export function FileUploaderModal({
  data,
  ...props
}: FileUploaderModalWrapperProps) {
  const { fileId } = data;
  const fileInfo = useFileInfoById(fileId);

  if (fileId && fileInfo.isLoading) {
    return (
      <FileUploader
        key="loading-placeholder"
        {...props}
        data={data}
        isLoading
      />
    );
  }

  if (!fileId) {
    return <FileUploader key="new" {...props} data={data} />;
  }

  return (
    <FileUploader
      key="edit"
      {...props}
      data={{ ...data, initialValues: fileInfo.data }}
    />
  );
}
